import { template as template_fb4835fe26cc4f7facfb09866bed99ac } from "@ember/template-compiler";
const FKLabel = template_fb4835fe26cc4f7facfb09866bed99ac(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
