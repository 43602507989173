import { template as template_d3aedcc13fb649049787cad32634b3cd } from "@ember/template-compiler";
const WelcomeHeader = template_d3aedcc13fb649049787cad32634b3cd(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
