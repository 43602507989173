import { template as template_7d1ff67cf01e4295bcb3cecfbafa713f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_7d1ff67cf01e4295bcb3cecfbafa713f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
