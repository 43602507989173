import { template as template_d9c6bdfae37c4a3e90059fc8ea3c23f0 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d9c6bdfae37c4a3e90059fc8ea3c23f0(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
